var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "auditor-badge d-flex items-center py-2 px-3 rounded bg-white",
    },
    [
      _c("SvgIcon", {
        staticClass: "mr-2",
        attrs: { width: "24", height: "24", name: "binoculars" },
      }),
      _vm._v(" Auditor "),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }