var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { staticClass: "room-detail activity-feed-card", attrs: { grow: "" } },
    [
      _c("div", {
        staticClass: "room-detail-image",
        staticStyle: {
          "background-image":
            "url('https://assets.thegogame.com/gamephotos/c51a01df-85ae-4215-81fe-46b4897d7466.png')",
        },
      }),
      _c(
        "div",
        {
          staticClass: "room-detail-info rtb-d-flex flex-column h-0 flex-grow",
        },
        [
          _c(
            "v-layout",
            { attrs: { row: "", shrink: "" } },
            [
              _c("v-flex", { attrs: { xs3: "", shrink: "" } }, [
                _c("div", { staticClass: "main-title" }, [_vm._v("PHOTOS")]),
              ]),
              _c("v-spacer"),
              _c(
                "v-flex",
                { attrs: { shrink: "" } },
                [
                  _c(
                    "rtb-button",
                    {
                      staticClass: "mr-1 text-capitalize mt-2",
                      attrs: { disabled: !_vm.plays.length || _vm.working },
                      on: { click: _vm.download },
                    },
                    [
                      [
                        _vm._v(" Download All "),
                        _vm.working
                          ? _c("v-progress-circular", {
                              attrs: { indeterminate: "" },
                            })
                          : _vm._e(),
                      ],
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm.isHost
                ? _c(
                    "v-flex",
                    { attrs: { xs1: "", shrink: "" } },
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function (ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          attrs: {
                                            title: "Close",
                                            icon: "",
                                            dark: "",
                                          },
                                          on: { click: _vm.remove },
                                        },
                                        on
                                      ),
                                      [
                                        _c("SvgIcon", {
                                          attrs: {
                                            name: "trash-regular",
                                            width: "20",
                                            height: "20",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            4046831777
                          ),
                        },
                        [_c("span", [_vm._v("Clear (Hosts Only)")])]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-flex",
                { attrs: { xs1: "", shrink: "" } },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    attrs: {
                                      title: "Close",
                                      icon: "",
                                      dark: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$emit("close")
                                      },
                                    },
                                  },
                                  on
                                ),
                                [
                                  _c("SvgIcon", {
                                    attrs: {
                                      name: "times-regular",
                                      width: "20",
                                      height: "20",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [_c("span", [_vm._v("Close")])]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "activity-feed h-0 flex-grow -ml-1" },
            [
              _c("DynamicScroller", {
                staticClass: "h-full rtl",
                attrs: { items: _vm.chunks, "min-item-size": 54 },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var chunk = ref.item
                      var index = ref.index
                      var active = ref.active
                      return [
                        _c(
                          "DynamicScrollerItem",
                          {
                            attrs: {
                              item: chunk,
                              active: active,
                              "data-index": index,
                            },
                          },
                          [
                            _c(
                              "v-layout",
                              {
                                staticClass: "ltr",
                                attrs: { row: "", wrap: "" },
                              },
                              _vm._l(chunk.items, function (item) {
                                return _c(
                                  "v-flex",
                                  { key: item.id, attrs: { xs4: "" } },
                                  [
                                    _c("v-img", {
                                      staticClass: "feed-image",
                                      attrs: {
                                        src: item.correct.image,
                                        "aspect-ratio": "1",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "placeholder",
                                            fn: function () {
                                              return [
                                                _c(
                                                  "v-layout",
                                                  {
                                                    attrs: {
                                                      "fill-height": "",
                                                      "align-center": "",
                                                      "justify-center": "",
                                                      "ma-0": "",
                                                    },
                                                  },
                                                  [
                                                    _c("v-progress-circular", {
                                                      attrs: {
                                                        indeterminate: "",
                                                        color: "primary",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "layout player-lobby-details justify-center row",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "player-name",
                                            staticStyle: {
                                              "font-size": "14px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.firstName) + " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c("div", { staticClass: "text" }, [
                                      _vm._v(_vm._s(item.message)),
                                    ]),
                                  ],
                                  1
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }