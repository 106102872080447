import { render, staticRenderFns } from "./RoundMover.vue?vue&type=template&id=6d14d7fc&"
import script from "./RoundMover.vue?vue&type=script&lang=js&"
export * from "./RoundMover.vue?vue&type=script&lang=js&"
import style0 from "./RoundMover.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCheckbox,VFlex,VLayout,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("/Users/pavlo/projects/go-game-platform/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6d14d7fc')) {
      api.createRecord('6d14d7fc', component.options)
    } else {
      api.reload('6d14d7fc', component.options)
    }
    module.hot.accept("./RoundMover.vue?vue&type=template&id=6d14d7fc&", function () {
      api.rerender('6d14d7fc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Lobby/RoundMover.vue"
export default component.exports