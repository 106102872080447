import { render, staticRenderFns } from "./LiveChat.vue?vue&type=template&id=6ad4344d&"
import script from "./LiveChat.vue?vue&type=script&lang=js&"
export * from "./LiveChat.vue?vue&type=script&lang=js&"
import style0 from "./LiveChat.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VFlex,VIcon,VLayout,VList,VListTile,VListTileTitle,VMenu,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("/Users/pavlo/projects/go-game-platform/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6ad4344d')) {
      api.createRecord('6ad4344d', component.options)
    } else {
      api.reload('6ad4344d', component.options)
    }
    module.hot.accept("./LiveChat.vue?vue&type=template&id=6ad4344d&", function () {
      api.rerender('6ad4344d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Lobby/LiveChat.vue"
export default component.exports