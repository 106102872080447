import { render, staticRenderFns } from "./ChoiceBtn.vue?vue&type=template&id=c91e6420&"
import script from "./ChoiceBtn.vue?vue&type=script&lang=ts&"
export * from "./ChoiceBtn.vue?vue&type=script&lang=ts&"
import style0 from "./ChoiceBtn.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VBtn})


/* hot reload */
if (module.hot) {
  var api = require("/Users/pavlo/projects/go-game-platform/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c91e6420')) {
      api.createRecord('c91e6420', component.options)
    } else {
      api.reload('c91e6420', component.options)
    }
    module.hot.accept("./ChoiceBtn.vue?vue&type=template&id=c91e6420&", function () {
      api.rerender('c91e6420', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/GroupTeams/Common/Games/GameCardParts/ChoiceBtn.vue"
export default component.exports