







import Vue from "vue"
import { mapGetters } from "vuex"
import RoomDetailLayout from "./RoomDetailLayout.vue"

const DEFAULT_HEADER_IMAGE =
  "https://assets.thegogame.com/gamephotos/7ad07a61-03fc-4232-a9ef-3d1cee7c2b53.png"
export default Vue.extend({
  name: "GamePlaceholder",
  components: {
    RoomDetailLayout
  },
  computed: {
    ...mapGetters("auth", ["client"]),
    imageTitle() {
      const image =
        this.client?.newLobbyCustomLogo ||
        this.client?.welcomeImage ||
        DEFAULT_HEADER_IMAGE
      return image
    }
  }
})
