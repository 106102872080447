<template>
  <div>
    <div class="form-loader-wrapper" v-if="!ready">
      <v-progress-circular indeterminate color="#F5665D" size="80" />
    </div>
    <div v-else>
      <EmailForm
        v-if="mode === 'login'"
        :id="id"
        :enterLoginText="session && session.enterLoginText"
        :setForgotPassword="
          newMode => {
            mode = 'forgot-password'
          }
        "
      />
      <AnonymousForm
        v-else-if="mode === 'anonymous'"
        :id="id"
        :customInputType="customInputType"
        :customInputOptions="customInputOptions"
        :customInputLabel="customInputLabel"
        :enterLoginText="session && session.enterLoginText"
        :conferenceLogin="session && session.conferenceLogin"
      />
      <EmailCSVForm
        v-else-if="mode === modes.EmailAndNames"
        :id="id"
        :client="session"
      />
      <ForgotPassword
        v-else-if="mode === 'forgot-password'"
        :setLogin="
          () => {
            mode = 'login'
          }
        "
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"

import { MODULE_NAME as THEME_MODULE_NAME } from "@/modules/theme/store"
import ThemeModuleMutationTypes from "@/modules/theme/store/mutation-types"
import LoginCustomInput from "@shared/enums/LoginCustomInput"
import { OrgService } from "@/services/org.service"
import { fetchGame } from "@/services/game.service"
import { MailMatcher } from "@/services/mail.matching"

import {
  verifyPasswordResetCode,
  applyActionCode
} from "@/services/auth.service"
import { Navigation } from "@/helpers"
import { GameLoginModes } from "../types"

import {
  MODULE_NAME as AUTH_MODULE_NAME,
  MutationTypes as AuthModuleMutationTypes
} from "@/store/AuthModule"

class MissingResourceError extends Error {}

import LoginMixin from "@/components/User/LoginAndOnboarding/mixins/LoginMixin"
import AnonymousForm from "./forms/AnonymousForm"
import EmailForm from "./forms/EmailForm"

export default {
  name: "LoginFormsWrapper",
  mixins: [LoginMixin],
  components: {
    AnonymousForm,
    EmailForm,
    EmailCSVForm: () => import("./forms/EmailCSVForm"),
    ForgotPassword: () => import("./forms/ForgotPassword")
  },
  props: ["id", "setTitle", "setStep", "session", "sessionId"],
  data() {
    return {
      modes: { ...GameLoginModes },
      customInput: null,
      customInputType: null,
      customInputOptions: [],
      customInputLabel: null,
      enterLoginText: null,
      email: "",
      mode: GameLoginModes.Anonymous,
      username: "",
      password: "",
      firstname: null,
      lastname: null,
      game: null,
      title: null,
      initializing: true,
      actionCode: null,
      continueUrl: null,
      simpleForm: false,
      TEMP__title: null
    }
  },
  computed: {
    ...mapGetters("auth", ["error", "user", "status"]),
    ready() {
      return (
        this.status !== "loading" &&
        this.status !== "authorized" &&
        !this.initializing
      )
    },
    identifier() {
      return this.customInput
        ? this.customInput
        : this.customInputType &&
          this.customInputOptions &&
          this.customInputOptions.length
        ? // default to the last item on the list
          this.customInputOptions[this.customInputOptions.length - 1]
        : null
    },
    customID() {
      return Navigation.parseUrlID(this.id)
    },
    gameID() {
      return this.customID ? this.customID.gameID : null
    }
  },
  async created() {
    const {
      anon,
      mode: queryMode,
      oobCode: actionCode,
      continueUrl
    } = this.$route.query

    const now = Date.now()

    if (this.sessionId) {
      this.$store.commit("auth/UPDATE_CLIENT_ID", { clientID: this.sessionId })
    }

    let mode = null

    if (queryMode === "resetPassword") {
      mode = "reset-password"
      // this.title = "Reset Password"
      this.actionCode = actionCode
      this.continueUrl = continueUrl
      try {
        this.email = await verifyPasswordResetCode(this.actionCode)
      } catch ({ message }) {
        this.updateError({ message })
      }
    } else if (queryMode === "verifyEmail") {
      mode = "verify-email"
      // this.title = "Email verification"
      this.continueUrl = continueUrl
      try {
        await applyActionCode(actionCode)
      } catch ({ message }) {
        this.updateError({ message })
      }
    } else {
      if (this.sessionId) {
        try {
          if (!this.session) {
            throw new MissingResourceError(
              `The resource doesn't exist or it's expired.`
            )
          }

          await this.$store.commit(
            `${AUTH_MODULE_NAME}/${AuthModuleMutationTypes.UPDATE_CLIENT}`,
            { client: this.session }
          )

          if (this.session.themeID) {
            const themes = await OrgService.getOrgThemes(this.session.orgID)

            if (themes && themes[this.session.themeID]) {
              this.$store.commit(
                `${THEME_MODULE_NAME}/${ThemeModuleMutationTypes.SET_THEME}`,
                themes[this.session.themeID]
              )
            }
          }

          // if the number of attendees is blank or 0
          // or the has reached the max capacity
          // then redirect to the link listed right there.
          if (this.session.redirectURL && !this.session.maxCapacity) {
            window.location = this.session.redirectURL
          }

          const {
            orgID,
            gameID: clientGameID,
            logrocket,
            regularLogin,
            customInputType,
            customInputOptions,
            customInputLabel
          } = this.session

          this.simpleForm = await new MailMatcher(this.sessionId).exists()

          if (
            (customInputType === LoginCustomInput.Select ||
              customInputType === LoginCustomInput.Location) &&
            typeof customInputOptions === "string"
          ) {
            const array = customInputOptions.split(",")
            if (array.length > 1) {
              this.customInputType = customInputType
              this.customInputOptions = array
              this.customInputLabel = customInputLabel
            }
          } else if (customInputType === "text" && customInputLabel) {
            this.customInputType = customInputType
            this.customInputLabel = customInputLabel
          } else if (customInputType === "password") {
            this.customInputType = customInputType
            this.customInputLabel = customInputLabel || "Password"
          }

          if (this.simpleForm) {
            mode = GameLoginModes.EmailAndNames
          } else if (regularLogin) {
            mode = "login"
          }

          const gameID = this.gameID || clientGameID

          let title = this.session.name

          if (gameID && this.session.category !== "Open Weve") {
            const game = await fetchGame({ orgID, gameID })

            if (!this.session.tournament && !game) {
              throw new MissingResourceError(
                `Cannot find game data for ${this.sessionId}`
              )
            }

            if (game?.regularLogin) {
              mode = "login"
            }

            const gameTitle = game?.externalName || game?.name

            if (!this.session.tournament && gameTitle) title = gameTitle
          }

          this.setTitle(title)
          this.TEMP__title = title
        } catch (e) {
          console.warn(e)
          this.setTitle("")
          if (e instanceof MissingResourceError) {
            this.mode = "dead-end"
            await this.updateError({ message: e.message })
            this.initializing = false
            return
          }
        }
      } else {
        mode = "login"
      }

      if (anon === "1") {
        this.$store.commit("auth/UPDATE_ANON_AUTHORIZATION", true)
      } else if (anon === "0") {
        this.$store.commit("auth/UPDATE_ANON_AUTHORIZATION", false)
        mode = "login"
      } else if (!this.$store.getters["auth/isAnonAuthorization"]) {
        mode = "login"
      }
    }

    if (mode) {
      this.mode = mode
    }

    this.$emit("ready")

    this.initializing = false
  },
  watch: {
    error(value) {
      if (value) {
        this.$snackbar({
          message: value,
          variant: "warning",
          timeout: 7000,
          offset: 40
        })
      }
    },
    mode(val, oldVal) {
      if (val !== oldVal) {
        // What the hell?
        // this.$store.commit("auth/UPDATE_STATUS", { status: null })
        this.$store.commit("auth/UPDATE_ERROR", { message: null })
      }

      this.setTitle(this.TEMP__title || this.session?.name)
    }
  },
  beforeDestroy() {
    this.$store.commit(
      `${THEME_MODULE_NAME}/${ThemeModuleMutationTypes.SET_THEME}`,
      null
    )
  },
  methods: {
    ...mapActions("auth", [
      "updateError",
      "signUserInAnonymously",
      "getLocalClientSetting"
    ])
  }
}
</script>

<style lang="scss">
.form-loader-wrapper {
  min-height: 374px;
  min-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__error-message {
    color: $wrong-color;
    font-size: 20px;
  }
}

.login-form {
  font-size: 18px;

  &__title {
    margin-top: min(max(10px, 2vh), 30px);
    margin-bottom: 0;
    color: $secondary-card-text;
    font-weight: 700;
    font-size: 22px;
    line-height: 1;

    @media (max-width: 1366px) {
      margin-top: 15px;
    }

    @media (max-width: 1024px) {
      display: none;
    }
  }

  .submit-wrap {
    padding-top: 1vh;
  }

  form {
    padding: 1.5vh 0 0;

    .two-col-form {
      display: flex;
      justify-content: space-between;

      .two-col-input {
        width: calc(50% - 10px);
      }
    }

    p {
      margin-bottom: 12px;
      color: $card-form-labels;
      font-weight: 400;
    }

    input[type="text"],
    input[type="password"] {
      width: 100%;
      margin-bottom: 22px;
      padding: 0 12px 0 15px;
      color: $card-form-inputs-text;
      background-color: $card-form-inputs-bg;
      font-size: 18px;
      line-height: 24px;
      font-weight: 400;
      outline: none;

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px $card-form-inputs-bg inset !important;
      }
    }

    .v-input {
      margin-bottom: 2vh;

      &.v-input--checkbox {
        margin-bottom: 0;
        /* justify-content: center; */
      }

      .v-input__slot {
        height: 4.5vh;
        margin: 0;
        background-color: $card-form-inputs-bg;
      }

      .v-input__control {
        position: relative;

        .v-text-field__details {
          position: absolute;
          bottom: -20px;
        }
      }

      input {
        margin-bottom: 0;
      }

      .v-select__selections .v-select__selection {
        white-space: initial;
        overflow: initial;
        text-overflow: initial;
        position: initial;
        width: auto;
        max-width: 100%;
        left: 0;
        display: initial;
        margin: 0 0 0 15px;
      }
    }

    .terms-and-conditions {
      color: #586475;
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      text-align: center;
      margin-bottom: 24px;
      & > a {
        color: inherit;
      }
    }
  }

  input[type="submit"],
  &__submit {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    padding: 0 26px;
    height: 40px;
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    background-color: $primary_accent_color;
    outline: none;
    border-radius: 5px;
    transition: 0.5s;
    opacity: 0.85;
    cursor: pointer;

    &:disabled {
      opacity: 0.5 !important;
      cursor: unset;
    }

    &:hover {
      opacity: 1;
    }
  }

  &__submit-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 15%;
  }
}

.form-loader__container {
  position: absolute;
  left: 0;
  top: 10px;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: $card-form-inputs-bg;
}

.form-btns__wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form__secondary-button {
  text-align: center;
  // font-size: 20px;
  color: $card-submit-enabled;
  text-decoration: underline;
  cursor: pointer;

  // &.small {
  //   font-size: 14px;
  // }

  &.inline {
    display: inline;
  }

  &.tac {
    text-align: center;
    margin: 2vh 0;
  }
}
</style>
