import { render, staticRenderFns } from "./MatchEmailsModal.vue?vue&type=template&id=e05db9a4&"
import script from "./MatchEmailsModal.vue?vue&type=script&lang=ts&"
export * from "./MatchEmailsModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDialog } from 'vuetify/lib/components/VDialog';
installComponents(component, {VDialog})


/* hot reload */
if (module.hot) {
  var api = require("/Users/pavlo/projects/go-game-platform/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e05db9a4')) {
      api.createRecord('e05db9a4', component.options)
    } else {
      api.reload('e05db9a4', component.options)
    }
    module.hot.accept("./MatchEmailsModal.vue?vue&type=template&id=e05db9a4&", function () {
      api.rerender('e05db9a4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Game/MatchEmailsModal.vue"
export default component.exports