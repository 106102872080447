var render, staticRenderFns
import script from "./Timepicker.vue?vue&type=script&lang=ts&"
export * from "./Timepicker.vue?vue&type=script&lang=ts&"
import style0 from "./Timepicker.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/pavlo/projects/go-game-platform/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f9130938')) {
      api.createRecord('f9130938', component.options)
    } else {
      api.reload('f9130938', component.options)
    }
    
  }
}
component.options.__file = "src/components/ui/Timepicker/Timepicker.vue"
export default component.exports