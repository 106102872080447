import { render, staticRenderFns } from "./TwilioManager.vue?vue&type=template&id=3cae7e4e&"
import script from "./TwilioManager.vue?vue&type=script&lang=js&"
export * from "./TwilioManager.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/pavlo/projects/go-game-platform/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3cae7e4e')) {
      api.createRecord('3cae7e4e', component.options)
    } else {
      api.reload('3cae7e4e', component.options)
    }
    module.hot.accept("./TwilioManager.vue?vue&type=template&id=3cae7e4e&", function () {
      api.rerender('3cae7e4e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Twilio/TwilioManager.vue"
export default component.exports