import { render, staticRenderFns } from "./ClientSettingsAttendance.vue?vue&type=template&id=f69f6f98&"
import script from "./ClientSettingsAttendance.vue?vue&type=script&lang=ts&"
export * from "./ClientSettingsAttendance.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VList } from 'vuetify/lib/components/VList';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
installComponents(component, {VDataTable,VList,VListTile,VListTileTitle,VMenu,VProgressLinear})


/* hot reload */
if (module.hot) {
  var api = require("/Users/pavlo/projects/go-game-platform/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f69f6f98')) {
      api.createRecord('f69f6f98', component.options)
    } else {
      api.reload('f69f6f98', component.options)
    }
    module.hot.accept("./ClientSettingsAttendance.vue?vue&type=template&id=f69f6f98&", function () {
      api.rerender('f69f6f98', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Game/ClientSettingsAttendance.vue"
export default component.exports