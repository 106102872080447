import { render, staticRenderFns } from "./GiftExchangeUserCard.vue?vue&type=template&id=7ae4e97d&"
import script from "./GiftExchangeUserCard.vue?vue&type=script&lang=ts&"
export * from "./GiftExchangeUserCard.vue?vue&type=script&lang=ts&"
import style0 from "./GiftExchangeUserCard.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/pavlo/projects/go-game-platform/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7ae4e97d')) {
      api.createRecord('7ae4e97d', component.options)
    } else {
      api.reload('7ae4e97d', component.options)
    }
    module.hot.accept("./GiftExchangeUserCard.vue?vue&type=template&id=7ae4e97d&", function () {
      api.rerender('7ae4e97d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/GroupTeams/Common/Player/GiftExchangeUserCard/GiftExchangeUserCard.vue"
export default component.exports