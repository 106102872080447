module.exports = {
  SUBMIT: {
    id: "0001",
    src: "https://assets.thegogame.com/audio%2F-LroXpzuSTyvLT2mA87K%2FBendTarragon.mp3Fri%20Oct%2025%202019%2013%3A19%3A23%20GMT-0400%20(Eastern%20Daylight%20Time)?alt=media&token=4cf20a8c-7f47-4744-a4f7-03a33ed7b6e9"
  },
  CORRECT: {
    id: "0002",
    src: "https://assets.thegogame.com/audio%2F-Ltal8ocQGSYt988ovij%2Fright%20bell.mp3Thu%20Nov%2014%202019%2020%3A11%3A56%20GMT-0800%20(Pacific%20Standard%20Time)?alt=media&token=b9f57181-014a-4acb-a9f5-69824cd4e769"
  },
  WRONG: {
    id: "0003",
    src: "https://assets.thegogame.com/audio%2F-Ltal8ocQGSYt988ovij%2Ferror-buzz2.mp3Thu%20Nov%2014%202019%2020%3A22%3A12%20GMT-0800%20(Pacific%20Standard%20Time)?alt=media&token=aedbd4e0-aab8-47a0-aab8-9bfe477b582d"
  },
  GAME_OVER: {
    id: "0004",
    src: "https://assets.thegogame.com/audio/-MkAHpF-JlCFsPIMXeTB/d04723a1-8bdc-4dfb-ac9d-7c01af65cc4fprice%20is%20right%20over.mp3"
  },
  TILE_TURN: {
    id: "0005",
    src: "https://storage.googleapis.com/rtb-dev2000.appspot.com/audio/-MYtPBVL7wd7Gw9Q_ghI/d2d84f55-e9ee-4d5e-84bc-1a4bc65aec5etile-turn.mp3"
  },
  STRIKE: {
    id: "0006",
    src: "https://storage.googleapis.com/rtb-dev2000.appspot.com/audio/-MYtPBVL7wd7Gw9Q_ghI/e2b679c2-00b8-4abe-b0ce-08ccca75995astrike.mp3"
  },
  DAILY_DOUBLE: {
    id: "0007",
    src: "https://assets.thegogame.com/audio/undefined/5862f703-9f12-4694-b532-0285f67aacb2DailyDouble.mp3"
  }
}
