import { render, staticRenderFns } from "./TwoTruthsAsset.vue?vue&type=template&id=523f08e6&scoped=true&"
import script from "./TwoTruthsAsset.vue?vue&type=script&lang=js&"
export * from "./TwoTruthsAsset.vue?vue&type=script&lang=js&"
import style0 from "./TwoTruthsAsset.vue?vue&type=style&index=0&id=523f08e6&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "523f08e6",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VFlex,VLayout})


/* hot reload */
if (module.hot) {
  var api = require("/Users/pavlo/projects/go-game-platform/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('523f08e6')) {
      api.createRecord('523f08e6', component.options)
    } else {
      api.reload('523f08e6', component.options)
    }
    module.hot.accept("./TwoTruthsAsset.vue?vue&type=template&id=523f08e6&scoped=true&", function () {
      api.rerender('523f08e6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/GroupTeams/Common/Games/TwoTruthsAsset.vue"
export default component.exports