var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "PlayLayout",
    {
      staticClass: "upr",
      scopedSlots: _vm._u([
        {
          key: "left",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "upr__info" },
                [
                  _vm.scribe
                    ? _c("AppUserCard", {
                        key: _vm.scribe.id,
                        staticClass: "upr__user",
                        style: !_vm.mission.instructions ? "height: 90%" : null,
                        attrs: { user: _vm.scribe },
                      })
                    : _vm._e(),
                  _vm.mission.instructions
                    ? _c("ResizableText", {
                        staticClass: "upr__text",
                        attrs: { message: _vm.mission.instructions },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm.isViewerHostLike && !_vm.scribe
        ? [
            _c("ResizableText", {
              staticStyle: { flex: "1" },
              attrs: { message: "Select a Scribe" },
            }),
          ]
        : [
            _c("UnconditionalPositiveRegardStage", {
              attrs: {
                userID: _vm.userId,
                draggable: !_vm.isViewerHostLike,
                encrypted: _vm.userId !== _vm.viewerId,
              },
            }),
            !_vm.isViewerHostLike && !_vm.isPresenter
              ? _c("TextField", {
                  staticClass: "upr__field",
                  attrs: {
                    size: "sm",
                    placeholder: "Enter here…",
                    show: "show",
                    status: "active",
                    buttonText: "Add",
                    allowFreeSubmit: "",
                    maxTextLength: 15,
                  },
                  on: { onSubmit: _vm.onSubmit },
                })
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }