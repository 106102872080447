var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "powered-by d-flex items-center py-2 px-3 rounded bg-white",
    },
    [
      _vm._v(" Powered by "),
      _c("img", {
        staticClass: "ml-2",
        attrs: {
          src: require("@/assets/login/weve-logo-full.svg"),
          alt: "Weve Logo",
          width: "100",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }