import { render, staticRenderFns } from "./JeopardAiFinaleCategory.vue?vue&type=template&id=70bddd2d&"
import script from "./JeopardAiFinaleCategory.vue?vue&type=script&lang=ts&"
export * from "./JeopardAiFinaleCategory.vue?vue&type=script&lang=ts&"
import style0 from "./JeopardAiFinaleCategory.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/pavlo/projects/go-game-platform/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('70bddd2d')) {
      api.createRecord('70bddd2d', component.options)
    } else {
      api.reload('70bddd2d', component.options)
    }
    module.hot.accept("./JeopardAiFinaleCategory.vue?vue&type=template&id=70bddd2d&", function () {
      api.rerender('70bddd2d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/GroupTeams/Common/Games/JeopardAI/components/JeopardAiFinaleCategory.vue"
export default component.exports