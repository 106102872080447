var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "login-page",
      style: _vm.customLoginColor
        ? "background-color: " + _vm.customLoginColor + " !important"
        : "",
    },
    [
      _c(
        "div",
        { staticClass: "login-page-inner" },
        [
          _c("div", { staticClass: "login-card-wrapper" }, [
            _c(
              "div",
              { staticClass: "login-card" },
              [
                _c(
                  "v-layout",
                  {
                    staticClass: "login-card__head shrink",
                    class: { "remote-logo-container": _vm.step === 2 },
                    attrs: { row: "" },
                  },
                  [
                    _c(
                      "v-flex",
                      {
                        staticClass: "login-card__logo",
                        attrs: { "d-flex": "" },
                      },
                      [
                        _c("img", {
                          attrs: { src: _vm.$theme.getImage("logo") },
                        }),
                      ]
                    ),
                    _c(
                      "v-flex",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.skippable,
                            expression: "skippable",
                          },
                        ],
                        staticClass: "login-card__logout ml-2",
                        attrs: { shrink: "", id: "onboarding-skip-btn" },
                        on: { click: _vm.skip },
                      },
                      [_vm._v(" Skip ")]
                    ),
                    _vm.step > 0
                      ? _c(
                          "v-flex",
                          {
                            staticClass: "login-card__logout ml-2",
                            attrs: { shrink: "" },
                            on: { click: _vm.back },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.step === 1 ? "Logout" : "Back") +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c("LoginStepper", {
                  staticClass: "login-card__nav",
                  attrs: {
                    setStep: _vm.setStep,
                    steps: _vm.stepsList,
                    currentStep: _vm.step,
                  },
                }),
                _vm.isPermissionsStep && !_vm.isIRLSession
                  ? _c("PermissionsStep", {
                      attrs: { session: { name: _vm.stepTitle } },
                    })
                  : !_vm.isLoadingSession
                  ? _c(
                      "div",
                      { staticClass: "login-card__body" },
                      [
                        _c(
                          "h2",
                          {
                            staticClass: "login-card__title",
                            class: {
                              "login-card__title--small":
                                _vm.step > 0 && _vm.step != 3,
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.stepTitle) + " ")]
                        ),
                        _vm.step === 0
                          ? _c("LoginFormsWrapper", {
                              attrs: {
                                id: _vm.id,
                                sessionId: _vm.sessionId,
                                session: _vm.session,
                                setStep: _vm.setStep,
                                setTitle: _vm.setStepTitle,
                              },
                              on: {
                                ready: function ($event) {
                                  _vm.isLogInInitialized = true
                                },
                              },
                            })
                          : _vm.step === 1 || _vm.step === 2
                          ? _c("AudioVideoSetupWrapper", {
                              attrs: {
                                step: _vm.step,
                                setTitle: _vm.setStepTitle,
                                setStep: _vm.setStep,
                              },
                            })
                          : _vm.step === 3
                          ? _c("LockedCard")
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "login-page__badges" },
                  [
                    _c("PoweredBy"),
                    _vm.isAuditor ? _c("AuditorBadge") : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("LoginSlider", {
            attrs: { images: _vm.images, customImages: _vm.customImages },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }