import { render, staticRenderFns } from "./SocialPlayerCard.vue?vue&type=template&id=d1bf7c06&"
import script from "./SocialPlayerCard.vue?vue&type=script&lang=ts&"
export * from "./SocialPlayerCard.vue?vue&type=script&lang=ts&"
import style0 from "./SocialPlayerCard.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VIcon,VLayout,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/Users/pavlo/projects/go-game-platform/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d1bf7c06')) {
      api.createRecord('d1bf7c06', component.options)
    } else {
      api.reload('d1bf7c06', component.options)
    }
    module.hot.accept("./SocialPlayerCard.vue?vue&type=template&id=d1bf7c06&", function () {
      api.rerender('d1bf7c06', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/GroupTeams/Common/Player/SocialPlayerCard/SocialPlayerCard.vue"
export default component.exports